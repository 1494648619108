import React, { useState } from 'react';
import OnVisible from 'react-on-visible';

const FadeIn = ({ className, delay = '0.2s', wrappingElement = 'div', children }) => {
  const [isVisible, setIsVisible] = useState(false);
  return (
    <OnVisible
      className={className}
      wrappingElement={wrappingElement}
      style={{
        opacity: isVisible ? 1 : 0,
        transition: 'opacity .6s ease',
        transitionDelay: delay
      }}
      onChange={() => setIsVisible(true)}
      percent={80}
    >
      {children}
    </OnVisible>
  );
}

export default FadeIn;