import React from 'react';
import { ImagePass } from '../../Image/ImagePass';
import { Breadcrumbs } from '../../Breadcrumbs';
import FadeIn from '../../FadeIn';
import './StandardHeader.scss';

export const StandardHeader = (props) => {
  const {
    pageTitle, // passed in from /templates/page.js
    standardHeaderTitleBold: titleBold,
    standardHeaderTitleRegular: titleRegular,
    standardHeaderSubtitleBold: subtitleBold,
    standardHeaderSubtitleRegular: subtitleRegular,
    standardHeaderImage: image,
    useSpan = false,
    location
  } = props;
  const TitleTag = useSpan ? 'span' : 'h1';
  return (
    <React.Fragment>
      <section className="standard-header">
        <ImagePass src={image} className="standard-header-image" />
        <div className="wrap">
          <FadeIn className="standard-header-content-wrap">
            {(subtitleBold || subtitleRegular) && (
              <span className="subtitle"><strong>{subtitleBold}</strong> {subtitleRegular}</span>
            )}
            {(titleBold || titleRegular) ? (
              <TitleTag className="title"><strong>{titleBold}</strong> {titleRegular}</TitleTag>
            ) : <TitleTag className="title">{pageTitle}</TitleTag>}
          </FadeIn>
        </div>
      </section>
      <section className="breadcrumbs-section">
        <div className="wrap">
          <Breadcrumbs location={location} />
        </div>
      </section>
    </React.Fragment>
  )
}
